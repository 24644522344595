@import "colors";
@import "bootstrap-variables";
@import "~bootstrap/scss/bootstrap";
@import "fonts";
@import "form";
@import "iframe";
@import "page/login.scss";

h1{
    font-size: 30px;
}