$color-sfvp-blue: #1c8ba6;
$color-sfvp-blue-light: #89bed0;
$color-sfvp-blue-dark: #00588d;
$color-sfvp-red: #a94442;
$color-white: #ffff;
$color-black: #000000;
$color-paris-blue: #01273c;

.sfvp-blue{
    color: $color-sfvp-blue;
}
.sfvp-blue-light{
    color: $color-sfvp-blue-light;
}
.sfvp-blue-dark{
    color: $color-sfvp-blue-dark;
}
.color-black{
    color: $color-black;
}

