$primary: $color-sfvp-blue; // #337ab7
$secondary: $color-sfvp-blue-light; // #337ab7

.btn-primary {
  color: $color-white !important;
}
.btn-secondary {
  color: $color-white !important;
}

.btn-outline-primary:hover {
  color: $color-white !important;
}